import polyglotI18nProvider from 'ra-i18n-polyglot';
import raEnglishMessages from 'ra-language-english';

const englishMessages = {
  ...raEnglishMessages,
  ['ra-relationships']: {
    duallistinput: { select: 'Add', unselect: 'Remove' },
  },
  authentication: {
    userCancelled: 'User cancelled the flow',
    login: {
      azure: 'SGS Login',
      keycloak: 'Airline Login',
      submit: 'Sign In',
      resetPassword: 'Reset Password?',
      email: 'Email',
    },
    changePassword: {
      fields: {
        currentPassword: 'Current Password',
        newPassword: 'New Password',
        confirmNewPassword: 'Confirm New Password',
      },
      card: {
        content: 'Please change your password to continue signing in.',
      },
    },
    forgotPassword: {
      passwordChanged:
        'Password has been reset successfully. \nPlease sign-in with your new password.',
    },
  },
  resources: {
    booking: {
      name: 'Booking |||| Bookings',
      titles: {
        edit: 'Booking Detail',
      },
      tabs: {
        info: 'Booking Info',
        inbound: 'Inbound Schedule',
        outbound: 'Outbound Schedule',
        services: 'Services Required',
      },
      fields: {
        referenceNumber: 'Booking Reference',
        type: 'Type',
        requiredAt: 'Date/Time',
        customer: 'Customer',
        airport: {
          id: 'Airport',
          code: 'Airport',
        },
        servicesAssigned: 'Services Assigned',
        servicesPending: 'Services Pending',
        status: 'Status',
        requiredAtFrom: 'Date From',
        requiredAtTo: 'Date To',
        aircraft: {
          callSign: 'Call Sign',
          aircraftType: 'Aircraft Type',
          operator: 'Operator',
          airline: {
            id: 'Airline',
            name: 'Airline',
          },
          registration: 'Registration',
          purpose: 'Purpose',
          purposeText: 'Purpose (Additional Information)',
          maximumTakeoffWeightKg: 'MTOW (kg)',
        },
        inboundSchedule: {
          flightNumber: 'Flight Number',
          departureAirport: { code: 'Departing Station' },
          departureTime: 'Date and Time of Departure',
          arrivalTime: 'Date and Time of Arrival',
          arrivalAirport: { code: 'Arrival Station' },
          crewCount: 'Crew Count',
          passengerCount: 'Passenger Count',
          cargoWeightKg: 'Cargo Weight (kg)',
        },
        outboundSchedule: {
          flightNumber: 'Flight Number',
          departureAirport: { code: 'Departing Station' },
          departureTime: 'Date and Time of Departure',
          arrivalTime: 'Date and Time of Arrival',
          arrivalAirport: { code: 'Arrival Station' },
          crewCount: 'Crew Count',
          passengerCount: 'Passenger Count',
          cargoWeightKg: 'Cargo Weight (kg)',
        },
      },
      actions: {
        confirmBooking: 'Payment Received',
        cancelBooking: 'Cancel Booking',
      },
      modals: {
        paymentReceived: {
          title: 'Payment Received',
          contentLine1: 'Are you sure you want to confirm this booking?',
          contentLine2: 'This action can not be undone.',
        },
        cancelBooking: {
          title: 'Cancel Booking',
          contentLine1: 'Are you sure you want to cancel this booking?',
          contentLine2: 'This action can not be undone.',
        },
      },
    },
    operatorTask: {
      name: 'Operator Task |||| Operator Tasks',
      tabs: {},
      fields: {
        id: 'Reference No',
        bookingRef: 'Booking Reference',
        quantity: 'Quantity',
        service: {
          name: 'Service',
          cost: 'Cost per Item (SAR)',
        },
        serviceCategory: {
          name: 'Service Category',
        },
        status: 'Status',
        assignee: {
          name: 'Assignee',
        },
        dateFrom: 'Date From',
        dateTo: 'Date To',
        airport: {
          code: 'Airport',
        },
        requiredAt: 'Date/Time',
      },
      drawer: {
        titleAssign: 'Assign Task',
        titleReassign: 'Reassign Task',
        body: 'Type or select from the list of airline operators',
        submit: 'Assign to Operator',
        assignee: 'Assignee',
      },
      actions: {
        assign: 'Assign',
        reassign: 'Reassign',
        start: 'Start',
        complete: 'Complete',
        cancel: 'Cancel',
      },
      modals: {
        title: '%{action} Task',
        contentLine1: 'Are you sure you want to %{action} this task?',
        contentLine2: 'This action can not be undone.',
      },
    },
    service: {
      name: 'Service |||| Services',
      tabs: { detail: 'Service Detail', availability: 'Availability' },
      titles: {
        edit: 'Service Detail',
      },
      fields: {
        referenceNumber: 'Reference Number',
        name: 'Service',
        type: 'Type',
        status: 'Status',
        availability: 'Availability (Airports)',
        orderIndex: 'Order Index',
        category: {
          id: 'Category',
        },
        description: 'Service Description',
        cost: 'Cost per Item (SAR)',
        maximumQuantity: 'Maximum Quantity',
        upsellServiceOneId: 'Upsell Service 1',
        upsellServiceTwoId: 'Upsell Service 2',
        icon: 'Select an Icon',
        image: 'Service Image',
      },
    },
    serviceCategory: {
      name: 'Service Category |||| Service Categories',
      tabs: {},
      titles: {
        edit: 'Service Category Detail',
      },
      fields: {
        code: 'Category Code',
        name: 'Category Name',
        orderIndex: 'Order Index',
        image: 'Category Image',
      },
    },
    servicePackage: {
      name: 'Service Package |||| Service Packages',
      tabs: {},
      titles: {
        edit: 'Service Package Detail',
      },
      fields: {
        referenceNumber: 'Reference No',
        name: 'Package Name',
        numberOfServices: 'Number of Services',
        orderIndex: 'Order Index',
        image: 'Package Image',
        serviceIds: 'Package Services',
      },
    },
    review: {
      name: 'Review |||| Reviews',
      titles: {
        list: 'Review Management',
      },
      tabs: {},
      fields: {
        bookingRef: 'Booking Reference',
        dateOfReview: 'Date',
        airport: {
          code: 'Airport',
        },
        starRating: 'Star Rating',
        dateOfService: 'Date of Service',
        comment: 'Comment',
        dateOfReviewFrom: 'Date From',
        dateOfReviewTo: 'Date To',
      },
      drawer: {
        title: 'Review Detail',
      },
    },
    notification: {
      name: 'Notification |||| Notifications',
      tabs: {},
      titles: {
        edit: 'Notification Detail',
      },
      fields: {
        language: 'Language',
        dispatchAt: 'Dispatch Date',
        target: {
          bookingType: 'Target',
          bookingsFrom: 'Target Bookings From',
          bookingsTo: 'Target Bookings Until',
          serviceId: 'Target Service',
        },
        title: 'Notification Title',
        body: 'Notification Text',
        status: 'Dispatch Status',
        allServices: 'All',
        targetServiceIdHelperText: 'Leave blank to target all services',
      },
      actions: { revoke: 'Revoke' },
      modals: {
        title: 'Revoke Notification',
        contentLine1: 'Are you sure you want to revoke this notification?',
        contentLine2: 'This action can not be undone.',
      },
    },
    faq: {
      name: 'FAQ |||| FAQs',
      tabs: {},
      titles: {
        list: 'FAQ Management',
        edit: 'FAQ Detail',
      },
      fields: {
        orderIndex: 'Order Index',
        question: 'Question',
        answer: 'Answer',
      },
    },
    airline: {
      name: 'Airline Management |||| Airline Management',
      titles: {
        edit: 'Airline Detail',
      },
      tabs: {
        overview: 'Overview',
        bookings: 'Bookings',
        operators: 'Operators',
        aircraft: 'Aircraft',
        filters: {
          bookings: {
            referenceNumber: 'Booking Ref',
            airport: 'Airport',
            status: 'Status',
            requiredAtFrom: 'Date From',
            requiredAtTo: 'Date To',
          },
          operators: {
            email: 'Email',
          },
          aircraft: {
            registration: 'Registration',
          },
        },
      },
      fields: {
        name: 'Airline Name',
        numberOfOperators: 'Airline Operators',
        numberOfActiveBookings: 'Active Bookings',
        primaryContactEmail: 'Contact Email',
        primaryContactPhone: 'Contact Phone',
      },
    },
    airlineOperator: {
      name: 'Operator Management |||| Operator Management',
      tabs: {},
      titles: {
        edit: 'Operator Detail',
      },
      fields: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        role: 'Role',
        airline: {
          id: 'Airline',
          name: 'Airline',
        },
        assignedAirport: 'Airport',
      },
    },
    customerCare: {
      name: 'Customer Care |||| Customer Care',
      title: 'Customer Care',
      tabs: {},
      fields: {
        name: 'Customer Care Name',
        addressLine1: 'Address Line 1',
        addressLine2: 'Address Line 2',
        addressCity: 'City',
        addressPostcode: 'ZIP/Postcode',
        addressCountry: 'Country',
        phone: 'Telephone',
        email: 'Email',
      },
    },
    airport: {
      name: 'Airport Contact |||| Airport Contacts',
      tabs: {},
      titles: {
        list: 'Airports',
        edit: 'Airport Detail',
      },
      fields: {
        code: 'Airport Code',
        name: 'Airport Name',
        addressLine1: 'Address Line 1',
        addressLine2: 'Address Line 2',
        addressCity: 'City',
        addressPostcode: 'ZIP/Postcode',
        addressCountry: 'Country',
        phone: 'Telephone',
        email: 'Email',
        isSgsSupported: 'Supported',
      },
    },
    taskAssignee: {
      name: 'Task Assignee |||| Task Assignees',
      tabs: {},
      fields: {},
    },
    aircraft: {
      name: 'Aircraft |||| Aircrafts',
      createTitle: 'Create Aircraft',
      editTitle: 'Aircraft Detail',
      tabs: {},
      fields: {
        registration: 'Registration',
        callSign: 'Call Sign',
        type: 'Type',
        purpose: 'Purpose',
        purposeText: 'Purpose Reason',
        mtowKg: 'MTOW (kg)',
      },
    },
  },
  error: {
    generic: 'Something went wrong. Please try again',
    permissions: {
      title: 'Access Denied',
      header: 'You do not have the required permissions to view this page',
      subtitle: 'Contact your administrator for necessary permissions',
    },
    form: {
      multiLang: {
        missingField:
          'The "%{source}" field is not complete for the %{language} translation',
        noLocalisations: 'Must have at least one defined localisation',
      },
    },
    field: {
      number: {
        notInt: 'Not an integer',
        minIs1: 'Must be greater than zero',
      },
      image: {
        notFound: 'Image not found',
      },
      file: {
        notFound: 'No file found',
      },
      text: {
        invalidEmail: 'Not a valid email address',
      },
    },
    session: {
      expired: 'Session expired',
    },
  },
  messages: {
    na: 'N/A',
    view: 'View',
    unknown: 'Unknown',
  },
  choices: {
    generic: {
      boolean: {
        true: 'Yes',
        false: 'No',
      },
    },
    booking: {
      status: {
        pending: 'Pending Payment',
        confirmed: 'Confirmed',
        completed: 'Completed',
        cancelled: 'Cancelled',
        failed: 'Failed'
      },
      type: {
        passenger: 'Passenger',
        airline: 'Airline',
      },
      purpose: {
        private: 'Private',
        commercial: 'Commercial',
        cargo: 'Cargo',
        other: 'Other',
      },
    },
    operatorTask: {
      status: {
        pending: 'Pending',
        pendingAssignment: 'Pending Assignment',
        confirmed: 'Confirmed',
        completed: 'Completed',
        cancelled: 'Cancelled',
      },
    },
    service: {
      status: {
        active: 'Active',
        draft: 'Draft',
        inactive: 'Inactive',
      },
      type: {
        passenger: 'Passenger',
        airline: 'Airline',
      },
    },
    airlineOperator: {
      role: {
        operator: 'Operator',
        manager: 'Manager',
      },
    },
    notification: {
      status: {
        scheduled: 'Scheduled',
        revoked: 'Revoked',
        sent: 'Sent',
        failed: 'Failed',
      },
      target: {
        passenger: 'Passengers',
        airline: 'Airline Operators',
      },
    },
  },
  multiLang: {
    button: {
      removeTranslation: 'Remove Translation',
    },
  },
  validation: {
    passwdSpecial: 'Password must contain a special character (?!#%$)',
    passwdNumber: 'Password must contain a number',
    passwdLower: 'Password must contain a lowercase character',
    passwdUpper: 'Password must contain an uppercase character',
    passwdMinLen: 'Password must be at least 8 characters',
    passwdMaxLen: 'Password must be no more than 99 characters',
    passwdNoMatch: 'The passwords do not match',
    invalidEmail: 'Invalid email format',
    invalidPhone: 'Not a valid phone number of the form +17895551234',
    invalidUrl: 'Invalid URL format',
    notAnInt: 'Not an integer',
    positivieNonZeroInt: 'Please enter a number greater than 0',
    positivieInt: 'Please enter a positive integer',
  },
};

const i18nProvider = polyglotI18nProvider((locale) => englishMessages, 'en', {
  allowMissing: true,
});

export { englishMessages };

export default i18nProvider;
