import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import theme, { colors } from 'theme';

// assets
import loginBackground from 'assets/login-background.png';

export const appStyles = makeStyles({
  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '20%',
    marginLeft: '50%',
  },
  circularProgress: {
    color: 'rgb(60, 70, 87)',
  },
  devCommit: {
    position: 'sticky',
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 0,
    padding: 6,
    backgroundColor: '#efefef',
    textAlign: 'center',
  },
});

export const columnStyles = (
  flexDirection = 'column',
  width = '20vw',
  extraStyles = {}
) =>
  makeStyles({
    root: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      marginBottom: 10,
    },
    column: {
      display: 'flex',
      flexDirection,
      width: flexDirection === 'column' ? width : '100%',
      minWidth: flexDirection === 'column' ? width : '100%',
      marginRight: 25,
    },
    columnWithExtraStyles: {
      display: 'flex',
      flexDirection,
      width: flexDirection === 'column' ? width : '100%',
      marginRight: 25,
      ...extraStyles,
    },
    additionalFields: {
      display: 'flex',
      width: '80vw',
      margin: 25,
    },
  })();

export const rowStyles = () =>
  makeStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 10,
      justifyContent: 'flex-start',
    },
    rowItems: {
      marginRight: 15,
      width: '15vw',
    },
  })();

export const toolbarStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  saveButton: {
    padding: '7px 18px 7px 18px',
  },
  deleteButton: {
    marginLeft: 'auto', // align to the right of the toolbar
    color: theme.palette.error.main,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

export const defaultButton = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  icon: {
    fontSize: 18,
  },
}));

export const linkButton = makeStyles({
  root: {
    paddingTop: '0.5%',
    marginTop: '2%',
    height: '6%',
  },
});

export const authFormStyles = () => ({
  form: {
    padding: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    marginTop: '1em',
    width: '90%',
    lineHeight: 'normal',
  },
  button: {
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.6)',
    display: 'flex',
    marginLeft: '7.5%',
    width: '85%',
    paddingTop: 15,
    paddingBottom: 15,
    marginBottom: '2%',
    fontSize: 20,
    ...theme.button,
  },
  buttonChangePassword: {
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.6)',
    display: 'flex',
    marginLeft: '7.5%',
    width: '85%',
    paddingTop: 15,
    paddingBottom: 15,
    marginBottom: '7%',
    fontSize: 20,
    ...theme.button,
  },
  buttonSecondary: {
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.6)',
    display: 'flex',
    marginLeft: '7.5%',
    width: '85%',
    paddingTop: 15,
    paddingBottom: 15,
    marginBottom: '7%',
    fontSize: 20,
    ...theme.button,
    backgroundColor: colors.lightBlue,
    '&:hover': {
      background: colors.darkBlue,
    },
  },
  loadingIndicator1: {
    color: colors.lightBlue,
  },
  loadingIndicator2: {
    color: colors.apple,
  },
  forgotpassword: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '7.5%',
    width: '85%',
    fontSize: 18,
    color: colors.darkGrey,
    marginBottom: '3%',
    '&:hover': {
      color: colors.lightBlue,
    },
  },
});

export const authPageStyles = () => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url(${loginBackground})`,
    backgroundColor: colors.lightGrey,
  },
  card: {
    width: 550,
    marginTop: '6em',
    borderRadius: '3%',
  },
  avatar: {
    margin: '2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 100,
    height: 100,
    margin: '2%',
    borderRadius: '25%',
  },
  orTypography: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.darkGrey,
    marginTop: '3%',
  },
});

export const authDialogStyles = () => ({
  root: {
    margin: 0,
    padding: 16,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: colors.grey,
  },
  dialogActionsRoot: {
    margin: 0,
    padding: 8,
  },
  dialogContentRoot: {
    padding: 16,
  },
  loadingIndicator: {
    color: colors.turquoise,
  },
  termsWindow: {
    whiteSpace: 'pre-wrap',
    width: '550px',
    overflow: 'auto',
  },
});

export const menuStyles = makeStyles({
  root: {
    marginLeft: 16,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: '0.5em',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '1.5em',
    },
  },
});

export const appBarStyles = makeStyles({
  appBar: {
    height: 50,
    display: 'flex',
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  select: {
    minWidth: 120,
    color: colors.white,
    borderColor: colors.white,
    '&:before': {
      borderColor: colors.white,
    },
    '&:after': {
      borderColor: colors.white,
    },
    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
      borderColor: colors.white,
    },
  },
  icon: {
    fill: colors.white,
  },
});

export const longTextStyles = makeStyles({
  root: {
    wordWrap: 'break-word',
  },
});

export const actionButtonStyles = (marginRight = 0) =>
  makeStyles({
    root: {
      paddingBottom: 15,
    },
    approve: {
      minWidth: '100px',
      color: colors.white,
      backgroundColor: colors.green,
      marginRight,
      '&:hover': {
        backgroundColor: colors.darkenedGreen,
      },
    },
    cancel: {
      color: colors.red,
    },
    floatRight: {
      float: 'right',
    },
  })();

export const centeredStyle = makeStyles({
  root: {
    margin: 50,
    marginRight: 'auto',
    marginLeft: 'auto',
    maxHeight: '10em',
  },
});

export const multiLangFormStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  removeTranslation: {
    height: '100%',
    color: colors.white,
    backgroundColor: colors.red,
    '&:hover': {
      backgroundColor: colors.darkenedRed,
    },
  },
});

export const horizontalList = makeStyles({
  root: {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    padding: 0,
    minWidth: '100px', // allows 6 emojis to be spaced
    justifyContent: 'space-between',
  },
});

export const tabbedLists = makeStyles({
  root: {
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  listFilterCreate: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const formStyles = () => ({
  form: {
    padding: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    marginTop: '1em',
    width: '90%',
    lineHeight: 'normal',
  },
  button: {
    display: 'flex',
    marginLeft: '7.5%',
    width: '85%',
    paddingTop: 15,
    paddingBottom: 15,
    marginBottom: '3%',
    fontSize: 16,
    ...theme.button,
  },
  loadingIndicator: {
    color: colors.turquoise,
  },
  forgotpassword: {
    display: 'flex',
    fontWeight: 'bold',
    marginLeft: '7.5%',
    width: '85%',
    color: colors.turquoise,
    textDecoration: 'none',
    '&:hover': {
      color: colors.darkTurquoise,
    },
  },
});

export const dialogStyles = () => ({
  root: {
    margin: 0,
    padding: 16,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: 'grey',
  },
  dialogActionsRoot: {
    margin: 0,
    padding: 8,
  },
  dialogContentRoot: {
    padding: 16,
  },
  loadingIndicator: {
    color: colors.turquoise,
  },
  termsWindow: {
    whiteSpace: 'pre-wrap',
    width: '550px',
    overflow: 'auto',
  },
});

export const accessDeniedStyles = makeStyles(
  (theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        height: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        height: '100vh',
        marginTop: '-3em',
      },
    },
    icon: {
      marginTop: 50,
      alignSelf: 'center',
      width: '5em',
      height: '5em',
      color: theme.palette.primary.main,
    },
    message: {
      textAlign: 'center',
      fontFamily: 'Roboto, sans-serif',
      opacity: 0.5,
      margin: '0 1em',
    },
    toolbar: {
      textAlign: 'center',
      marginTop: '2em',
      marginBottom: '2em',
    },
  }),
  { name: 'RaNotFound' }
);

export const drawerStyles = makeStyles({
  body: {
    display: 'flex',
    flexDirection: 'column',
    width: '350px',
    minWidth: '350px',
    margin: '25px',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    marginTop: '20px',
  },
  rowItem: {
    maxWidth: '48%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  button: {
    padding: '4px 10px',
    ...theme.button,
  },
  column: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '20px',
  },
});

export const iconPickerComponentStyles = (paddingBottom = '350px') =>
  makeStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom,
    },
    label: {
      marginLeft: 7,
    },
    error: {
      color: 'red',
      marginLeft: 7,
    },
  })();

export const previewImageStyles = ({
  margin = '0.5rem',
  maxHeight = '10rem',
}) =>
  makeStyles({
    root: {
      margin,
      maxHeight,
    },
  })();

export const linkStyles = makeStyles({
  root: {
    textDecoration: 'none',
    color: colors.apple,
    '&:hover': {
      color: colors.darkApple,
    },
  },
});

export const tabbedFilters = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  filterFields: {
    width: '15%',
    marginLeft: 10,
  },
  finalFilterField: {
    width: '15%',
    marginLeft: 10,
    marginRight: 10,
  },
});

export const muiTextField = makeStyles({
  root: { marginTop: 10, marginBottom: 27 },
});
