import gql from 'graphql-tag';

export const getListAirlineUserQuery = gql`
  query AllAirlineUsers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: AirlineUserFilter
  ) {
    items: allAirlineUsers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      firstName
      lastName
      email
      role
      airline {
        id
        name
      }
      airport {
        id
      }
    }
    total: _allAirlineUsersMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;
