import gql from 'graphql-tag';

export const getOneReviewQuery = gql`
  query Review($id: ID!) {
    data: Review(id: $id) {
      id
      airport {
        id
        code
      }
      bookingId
      bookingRef
      comment
      dateOfReview
      dateOfService
      starRating
    }
  }
`;
